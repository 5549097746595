// import { environment } from './../../../../../../Utility-tool/utilityBase/src/environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialAuthService } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { AuthenticationService } from 'src/app/services/auth/auth-service.service';
import { SharedService } from 'src/app/services/shared.service';
import { environment, environment1 } from 'src/environments/environment.prod';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  loginForm: FormGroup;
  otpForm: FormGroup;
  loading = false;
  emailId: string;
  password: any;
  newPassword: any;
  sendMail: string;
  confirmPassword: any;
  passwordMatchBoolean: boolean;
  fieldTextType: boolean;
  fieldTextTypeReset1: boolean;
  fieldTextTypeReset: boolean;
  loginboolean: boolean = true;
  forgotboolean: boolean = false;
  resetPassword: boolean = false;
  successPassword: boolean = false;
  loginBooleanSend: boolean = false;
  loginsuccess: boolean = false;
  numberRegEx = /^[\d\$]+$/
  keepMeLogin: boolean = false;
  otp: string;
  errorotp:string="";
  showOtpComponent = true;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  @ViewChild('ngOtpInput2', { static: false }) ngOtpInput2: any;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '50px',
      'height': '40px'
    }
  };
  userDetails = [
    { 'userId': 'prathapDS', 'password': '12345678', 'AccountType': 'customer' },
    { 'userId': 'DS2021', 'password': '12345678', 'AccountType': 'vendor' }
  ]
  showOtpPanel: boolean;
  showSendbtn: boolean = true;
  showVerifyBtn: boolean = false;
  otpData: any;
  paswrd: any;
  seconds = "59";
  minutes = "01";
  restricterr:string="";
  canresend: boolean = false;
  returnUrl: string;
  verifying:boolean = false;
  error = '';
  token: any;
  popupText: any;
  alertDivBoolean: boolean;
  submitted: boolean = false;
  User_type: string;
  errorMail: boolean;
  errorMailText: any;
  otp_login:string;

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,private msalService: MsalService,private googleService: SocialAuthService) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.User_type = this.authenticationService.currentUserValue.user_type;
      if (this.User_type === 'customer_portal') {
        this.router.navigate(['/customer']);
      } else if (this.User_type === 'vendor_portal') {
        this.router.navigate(['/vendorPortal']);
      }
    }
  }

  ngOnInit(): void {
    this.msalService.instance.handleRedirectPromise();
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    // this.otpForm = this.formBuilder.group({
    //   otp1:['', [Validators.required,Validators.maxLength(1),Validators.pattern(this.numberRegEx)]],
    //   otp2:['', [Validators.required,Validators.maxLength(1),Validators.pattern(this.numberRegEx)]],
    //   otp3:['', [Validators.required,Validators.maxLength(1),Validators.pattern(this.numberRegEx)]],
    //   otp4:['', [Validators.required,Validators.maxLength(1),Validators.pattern(this.numberRegEx)]],
    //   otp5:['', [Validators.required,Validators.maxLength(1),Validators.pattern(this.numberRegEx)]],
    //   otp6:['', [Validators.required,Validators.maxLength(1),Validators.pattern(this.numberRegEx)]]
    // })
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    // if(this.loginsuccess){
    //   setTimeout(() => {
    //     this.addEvent();
    //   }, 500);
    // }
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  toggleFieldTextTypeReset() {
    this.fieldTextTypeReset = !this.fieldTextTypeReset;
  }
  toggleFieldTextTypeReset1() {
    this.fieldTextTypeReset1 = !this.fieldTextTypeReset1;
  }
  test(event) {

    console.log("event", event.target.value)

    if (this.newPassword == this.confirmPassword) {
      this.passwordMatchBoolean = false;
    } else {
      this.passwordMatchBoolean = true;
    }
  }
  forgot() {
    this.loginboolean = false;
    this.forgotboolean = true;
    this.resetPassword = false;
    this.successPassword = false;
  }
  tryLogin() {
    this.loginboolean = true;
    this.forgotboolean = false;
    this.resetPassword = false;
    this.successPassword = false;
    this.showOtpPanel = false;
    this.showSendbtn = true;
  }
  sendOtp() {
    this.loading = true;
    let mailData = {
      mail: [this.sendMail]
    }
    this.sharedService.sendMail(this.sendMail).subscribe((data) => {
      console.log(data);
      if (data.result == "successful") {
        this.loading = false;
        this.showOtpPanel = true;
        this.showSendbtn = false;
        this.loginboolean = false;
        this.forgotboolean = false;
        this.resetPassword = true;
        this.successPassword = false;
        this.errorMail = false;
      }
    },err =>{
      this.errorMail = true;
      this.errorMailText = err.statusText
      this.loading = false;
    })
  }
  // verifyOtp() {
    
  //   // this.loading = true;
  //   // this.sharedService.verifyotp().subscribe((data) => {
  //   //   this.loading = false;
  //   //   if (data['OTP'] == this.otp) {
  //   //     this.loginboolean = false;
  //   //     this.forgotboolean = false;
  //   //     this.resetPassword = true;
  //   //     this.successPassword = false;
  //   //     this.showSendbtn = true;
  //   //     this.showOtpPanel = true;
  //   //   } else {
  //   //     alert("Enter valid OTP");
  //   //     this.showSendbtn = true;
  //   //     this.showOtpPanel = false;
  //   //   }
  //   //   console.warn(data['OTP'])
  //   //   this.otpData = data['OTP'];
  //   // })
  //   // console.log(this.otpData)
  // }
  resetPass() {

    this.loading = true;
    let updatePassword = {
      "activation_code": this.otp,
      "password": this.paswrd
    } 
    this.sharedService.updatepass(JSON.stringify(updatePassword)).subscribe(data => {
      
      this.loading = false;
      this.loginboolean = false;
      this.forgotboolean = false;
      this.resetPassword = false;
      this.successPassword = true;

    })
  }
  resetSuccess() {
    this.loginboolean = true;
    this.forgotboolean = false;
    this.resetPassword = false;
    this.successPassword = false;
  }
  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; };
  loginMS(){
    this.msalService.loginPopup().subscribe((response: AuthenticationResult) => {
      this.msalService.instance.setActiveAccount(response.account);
      this.login('ms');
    });
  }
  signInWithGoogle(): void {
    this.googleService.signIn(GoogleLoginProvider.PROVIDER_ID).then((response:any) =>{
      localStorage.setItem("ga.account.keys", response["email"]);
      this.login('ga');
    });
  }
  isLoggedInMs(): boolean {
    return this.msalService.instance.getActiveAccount() != null;
  }
  login(type) {
    this.submitted = true;
    this.loginsuccess = false;
    if(type == "ms"){
      this.f.username.setValue(this.msalService.instance.getActiveAccount().username);
      this.f.password.setValue("ms");
    }else if(type == "ga"){
      this.f.username.setValue(localStorage.getItem("ga.account.keys"));
      this.f.password.setValue("ga");
    }
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    let data1 = {
      "username": this.f.username.value,
      "password": this.f.password.value,
      "type":type
    }
    sessionStorage.setItem('username',JSON.stringify(data1.username));
    this.authenticationService.login(JSON.stringify(data1))
      .subscribe(
        data => {
          this.error = "";
          this.loading = false;
          if(data["status"]){
            this.loginsuccess = true;
            // setTimeout(() => {
            //   this.addEvent();
            // }, 500);
            let int = setInterval(()=>{
              if(Number(this.seconds) > 0){
                this.seconds = (Number(this.seconds) - 1).toString();
                if(Number(this.seconds) >= 0  && Number(this.seconds) <= 9){
                  this.seconds = "0"+(Number(this.seconds)).toString();
                }
              }else{
                this.seconds = "59";
                this.minutes = "0"+(Number(this.minutes) - 1).toString();
              }
              this.restricterr = `OTP Sent to Email! You can request for a new OTP after ${this.minutes}:${this.seconds}`;
              if(Number(this.seconds) <= 0 && Number(this.minutes) <= 0){
                this.seconds = "59";
                this.minutes = "01";
                this.restricterr = "";
                clearInterval(int);
                this.canresend = true;
              }
            },1000);
          }else if(data["token"]){
            if (this.returnUrl) {
              this.router.navigate([this.returnUrl]);
              } else if (data.user_type === 'customer_portal') {
                if(data.userdetails?.NameOfRole == 'Receiver'){
                  this.router.navigate(['/customer/Create_GRN_inv_list']);
                } else {
                  this.router.navigate(['/customer']);
                }
              } else if (data.user_type === 'vendor_portal') {
                this.router.navigate(['/vendorPortal']);
              }
              environment1.username = this.loginForm.controls["username"].value;
          }else{
            this.error = "Username or/and password are incorrect.";
            this.loginsuccess = false;
          }
        },
        error => {
          this.loginsuccess = false;
          this.loading = false;
          if (error.status === 401 || error.status === 404) {
            if(error.status === 401){
              this.error = "Username or/and password are incorrect.";
            }else{
              this.error = "User is not Registered!";
            }
            this.alertDivBoolean = true
          } else {
            this.error = "Username or/and password are incorrect.";
          }


        });
  }
  storeUser(e) {
    console.log(e.target.checked)
    this.keepMeLogin = e.target.checked;
    this.sharedService.keepLogin = e.target.checked;
  }
  onOtpChange(otp) {
    this.otp = otp;
  }
  onOtpAdding(otp){
    this.otp_login = otp;
  }
  addEvent(){
    for(let i=1;i<=6;i++){
      document.getElementById("otp"+i)?.addEventListener("input",function(e:any){
        let key = e.target.id;
        if((<HTMLInputElement>document.getElementById(key)).value.length == 1){
          let val = Number(key.split("tp")[1]) + 1;
          if(val > 6){
            val = 1;
          }
          let k = "otp"+val;
          console.log(k);
          (<HTMLInputElement>document.getElementById(k)).focus();
        }
      });
      document.getElementById("otp"+i)?.addEventListener("keydown",function(e:any){
        let key = e.target.id;
        let KeyID = e.keyCode;
        if(KeyID == 8 || KeyID == 46){
          (<HTMLInputElement>document.getElementById(key)).value = "";
          let val = Number(key.split("tp")[1]) - 1;
          if(val < 1){
            val = 6;
          }
          let k = "otp"+val;
          console.log(k);
          (<HTMLInputElement>document.getElementById(k)).focus();
        }
      });
    }
  }
  checkValidity(key:any){
    let otpval = this.otpForm.controls[key].errors;
    if(otpval?.hasOwnProperty("required") || otpval?.hasOwnProperty("maxlength") || otpval?.hasOwnProperty("pattern")){
      (<HTMLInputElement>document.getElementById(key)).style.border = "1px solid #eb2f06";
      return;   
    }else{
      (<HTMLInputElement>document.getElementById(key)).style.border = "1px solid #000000";
      let val = Number(key.split("tp")[1]) + 1;
      if(val > 6){
        val = 1;
      }
      let k = "otp"+val;
      (<HTMLInputElement>document.getElementById(k)).focus();
    }
  }
  resendOTP(){
    this.errorotp = "";
    if(this.canresend){
      this.authenticationService.resendOTP(this.loginForm.controls["username"].value).subscribe(data =>{
        if(data['status'] == "success"){
          this.canresend = false;
          let int = setInterval(()=>{
            if(Number(this.seconds) > 0){
              this.seconds = (Number(this.seconds) - 1).toString();
              if(Number(this.seconds) >= 0  && Number(this.seconds) <= 9){
                this.seconds = "0"+(Number(this.seconds)).toString();
              }
            }else{
              this.seconds = "59";
              this.minutes = "0"+(Number(this.minutes) - 1).toString();
            }
            this.restricterr = `OTP Sent to Email! You can request for a new OTP after ${this.minutes}:${this.seconds}`;
            if(Number(this.seconds) <= 0 && Number(this.minutes) <= 0){
              this.seconds = "59";
              this.minutes = "01";
              this.restricterr = "";
              clearInterval(int);
              this.canresend = true;
            }
          },1000);
        }
      });
    }
  }

  verifyOTP(){
    this.errorotp = "";
    // let otp1 = this.otpForm.controls["otp1"].errors;
    // let otp2 = this.otpForm.controls["otp2"].errors;
    // let otp3 = this.otpForm.controls["otp3"].errors;
    // let otp4 = this.otpForm.controls["otp4"].errors;
    // let otp5 = this.otpForm.controls["otp5"].errors;
    // let otp6 = this.otpForm.controls["otp6"].errors;
    // if(otp1?.hasOwnProperty("required") || otp1?.hasOwnProperty("maxlength") || otp1?.hasOwnProperty("pattern")){
    //   (<HTMLInputElement>document.getElementById("otp1")).style.border = "1px solid #eb2f06";
    //   return;   
    // }else{
    //   (<HTMLInputElement>document.getElementById("otp1")).style.border = "1px solid #000000";
    // }
    // if(otp2?.hasOwnProperty("required") || otp2?.hasOwnProperty("maxlength") || otp2?.hasOwnProperty("pattern")){
    //   (<HTMLInputElement>document.getElementById("otp2")).style.border = "1px solid #eb2f06";
    //   return;   
    // }else{
    //   (<HTMLInputElement>document.getElementById("otp2")).style.border = "1px solid #000000";
    // }
    // if(otp3?.hasOwnProperty("required") || otp3?.hasOwnProperty("maxlength") || otp3?.hasOwnProperty("pattern")){
    //   (<HTMLInputElement>document.getElementById("otp3")).style.border = "1px solid #eb2f06";
    //   return;   
    // }else{
    //   (<HTMLInputElement>document.getElementById("otp3")).style.border = "1px solid #000000";
    // }
    // if(otp4?.hasOwnProperty("required") || otp4?.hasOwnProperty("maxlength") || otp4?.hasOwnProperty("pattern")){
    //   (<HTMLInputElement>document.getElementById("otp4")).style.border = "1px solid #eb2f06";
    //   return;   
    // }else{
    //   (<HTMLInputElement>document.getElementById("otp4")).style.border = "1px solid #000000";
    // }
    // if(otp5?.hasOwnProperty("required") || otp5?.hasOwnProperty("maxlength") || otp5?.hasOwnProperty("pattern")){
    //   (<HTMLInputElement>document.getElementById("otp5")).style.border = "1px solid #eb2f06";
    //   return;   
    // }else{
    //   (<HTMLInputElement>document.getElementById("otp5")).style.border = "1px solid #000000";
    // }
    // if(otp6?.hasOwnProperty("required") || otp6?.hasOwnProperty("maxlength") || otp6?.hasOwnProperty("pattern")){
    //   (<HTMLInputElement>document.getElementById("otp6")).style.border = "1px solid #eb2f06";
    //   return;   
    // }else{
    //   (<HTMLInputElement>document.getElementById("otp6")).style.border = "1px solid #000000";
    // }
    // 
    // let otp = this.otpForm.controls["otp1"].value + this.otpForm.controls["otp2"].value + this.otpForm.controls["otp3"].value + this.otpForm.controls["otp4"].value + this.otpForm.controls["otp5"].value + this.otpForm.controls["otp6"].value;
    this.verifying = true;
    let optobj = {'username':this.loginForm.controls["username"].value,'otp':this.otp_login}
    this.authenticationService.verifyOTP(optobj).subscribe(data=>{
      console.log(data)
      this.verifying = false;
      if(data == "invalid"){
        this.errorotp = "The OTP is invalid/incorrect!";
        return;
      }else if(data == "otp expired"){
        this.errorotp = "The OTP has expired! Please generate a new one";
        return;
      }
      if (this.returnUrl) {
      this.router.navigate([this.returnUrl]);
      } else if (data.user_type === 'customer_portal') {
        if(data.userdetails?.NameOfRole == 'Receiver'){
          this.router.navigate(['/customer/Create_GRN_inv_list']);
        } else {
          this.router.navigate(['/customer']);
        }
      } else if (data.user_type === 'vendor_portal') {
        this.router.navigate(['/vendorPortal']);
      }
      environment1.username = this.loginForm.controls["username"].value;
      //window.location.reload();
    })
  }
}
