<h6 class="headh6">Document Summary</h6>

<div class="container-fluid">
  <div>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item mr-1">
        <a
          class="nav-link"
          id="vendor-tab"
          data-toggle="tab"
          href="#vendor"
          role="tab"
          aria-controls="vendor"
          aria-selected="true"
          >Vendor Based</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link active"
          id="vendor-tab"
          data-toggle="tab"
          href="#service"
          role="tab"
          aria-controls="service"
          aria-selected="true"
          >Service Based</a
        >
      </li>
    </ul>
  </div>
  <div class="filter_input" style="right: 50px">
    <p-calendar
      [(ngModel)]="rangeDates"
      selectionMode="range"
      [minDate]="minDate"
      [maxDate]="maxDate"
      showButtonBar="true"
      (onClearClick)="clearDates()"
      placeholder="Select dates to Filter"
      [readonlyInput]="true"
      [showIcon]="true"
      inputId="range"
    ></p-calendar>
    <button class="btnUpload filter_btn_m" (click)="filterData()">
      Filter
    </button>
  </div>

  <div
    class="tab-content tabDiv bg-design-all max_data_scroll pt-2 pb-2"
    id="myTabContent"
  >
    <div
      class="tab-pane fade"
      id="vendor"
      role="tabpanel"
      aria-labelledby="vendor-tab"
    >
      <div class="d-flex">
        <div class="card-div card1-bg d-flex mr-3 ml-2">
          <div class="img-wrapper bg-1">
            <mat-icon svgIcon="total_page"></mat-icon>
          </div>
          <div>
            <div class="text_color">Total Pages Count</div>
            <div class="f-15">{{ totalSuccessPages }}</div>
          </div>
        </div>

        <div class="card-div card2-bg d-flex">
          <div class="img-wrapper bg-2">
            <mat-icon svgIcon="total_inv"></mat-icon>
          </div>
          <div>
            <div class="text_color">Total Invoices</div>
            <div class="f-15">{{ totalInvoices }}</div>
          </div>
        </div>
      </div>

      <div class="table_scroll">
        <div class="tableDatDiv table_top">
          <app-table
            [tableData]="customerSummary"
            [invoiceColumns]="summaryColumn"
            [showPaginator]="showPaginatorSummary"
            [columnsToFilter]="summaryColumnField"
            [columnLength]="ColumnLengthVendor"
          >
          </app-table>
        </div>

        <ngx-spinner
          bdColor="rgba(251, 251, 251, 0.8)"
          size="medium"
          color="#070900"
          [fullScreen]="false"
          type="ball-spin-clockwise"
        >
          <p style="color: rgb(0, 0, 0)"></p>
        </ngx-spinner>
      </div>
    </div>

    <div
      class="tab-pane fade  show active"
      id="service"
      role="tabpanel"
      aria-labelledby="service-tab"
    >
      <div class="d-flex">
        <div class="card-div card2-bg d-flex">
          <div class="img-wrapper bg-2">
            <mat-icon svgIcon="total_inv"></mat-icon>
          </div>
          <div>
            <div class="text_color">Total Invoices</div>
            <div class="f-15">{{ totalInvoicesSP }}</div>
          </div>
        </div>
        <div class="card-div card1-bg d-flex mr-3 ml-2">
          <div class="img-wrapper bg-1">
            <mat-icon svgIcon="total_page"></mat-icon>
          </div>
          <div>
            <div class="text_color">Total Pages Count</div>
            <div class="f-15">{{ totalSuccessPagesSP }}</div>
          </div>
        </div>
        <div class="card-div card3-bg d-flex mr-3 ml-2">
          <div class="img-wrapper bg-3">
            <mat-icon svgIcon="total_page"></mat-icon>
          </div>
          <div>
            <div class="text_color">Total OCR Pages Count</div>
            <div class="f-15">{{ totalOCRSuccessPagesSP }}</div>
          </div>
        </div>
        
      </div>

      <div class="table_scroll">
        <div class="tableDatDiv table_top">
          <app-table
            [tableData]="customerSummarySP"
            [invoiceColumns]="summaryColumnSP"
            [showPaginator]="showPaginatorSummarySP"
            [columnsToFilter]="summaryColumnFieldSP"
            [columnLength]="ColumnLengthSP"
          >
          </app-table>
        </div>

        <ngx-spinner
          bdColor="rgba(251, 251, 251, 0.8)"
          size="medium"
          color="#070900"
          [fullScreen]="false"
          type="ball-spin-clockwise"
        >
          <p style="color: rgb(0, 0, 0)"></p>
        </ngx-spinner>
      </div>
    </div>
  </div>
</div>

<p-toast></p-toast>
